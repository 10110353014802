import React from 'react';
import { graphql } from 'gatsby';

import { ContentfulSettings } from '../generated/gatsby.types';
import Layout from '../components/layout';
import { NotFoundPage } from '../ui-lib/components';

type NotFoundQueryType = {
  contentfulCookieBanner: any;
  site: any;
  contentfulSettings: ContentfulSettings;
};

type PageContextType = {
  node_locale: string;
};

type NotFoundProps = {
  data: NotFoundQueryType;
  pageContext: PageContextType;
};

const NotFound = ({ data, pageContext }: NotFoundProps) => {
  const title = data.contentfulSettings.notFoundTitle;
  const shortDescription = data.contentfulSettings.notFoundDescription?.text;
  const homepageSlug = data.contentfulSettings.homepage.slug;

  const cta = {
    label: data.contentfulSettings.notFoundCta?.title,
    to: data.contentfulSettings.notFoundCta?.internalEntry.slug,
  };

  if (cta.to === homepageSlug) {
    cta.to = '/';
  }

  return (
    <Layout
      pageLocale={pageContext.node_locale}
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug=""
      contentfulSettings={data.contentfulSettings}
      cookieBannerTitle={data.contentfulCookieBanner?.title}
      cookieBannerDescription={data.contentfulCookieBanner?.description}
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
    >
      <NotFoundPage title={title} shortDescription={shortDescription} cta={cta} />
    </Layout>
  );
};

export default NotFound;

export const pageQuery = graphql`
  query($node_locale: String!, $brand_tag: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      title
      description {
        raw
      }
    }
    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }
  }
`;
